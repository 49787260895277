/* You can add global styles to this file, and also import other style files */
@import "ngb-lib/index.scss";

.loader {
  width: 88vw;
  height: 60vh;
  backdrop-filter: blur(10px);
}

.loader>svg {
  margin: auto;
  margin-top: 40vh;
  display: block;
  width: 40px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
